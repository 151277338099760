import { TerminalLocationModel } from "../../app/data/web-clock/models";
import { PunchModel } from "../../app/data/web-clock/models";

export default interface WebClockState {  
  punchDetails: PunchModel[] | null;
  locationId: string | null;
  locations: TerminalLocationModel[];
  recordType: string;
  request: {
    [key: string]: {
      requestStarted: boolean;
      requestSucceed: boolean;
      requestFailed: boolean;
      requestFailReason?: string | null;
    }
  }
}

export const initialWebClockState: WebClockState = {
  punchDetails: null,
  locationId: null,
  locations: [],
  request: {},
  recordType: "",
};