import React, { useEffect, ReactElement } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { ROUTES } from "../../app/route/RoutesConfig";
import XGSMap from "../../components/map/map";
import XGSMarker from "../../components/map/marker/marker";
import XGSPolyline from "../../components/map/polyline/polyline";
import XGSErrorMessage from "../../components/error-message/errorMessage";
import Loading from "../../components/loading/loading";
import RouteMapState from "../../slices/route-map/RouteMapState";
import { routeMapSelector } from "../../slices/route-map/routeMapSlice";
import "./routeMap.scss";

const RouteMap: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const routeMapState: RouteMapState = useSelector(routeMapSelector);
  const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_KEY || "";

  const ErrorComponent = () => (
    <XGSErrorMessage className="block-center">
      Failed to load map! Try again later or contact XGS support.
    </XGSErrorMessage>
  );

  const render = (status: Status): ReactElement => {
    if (status === Status.FAILURE) return <ErrorComponent />;
    return <Loading isLoading={true} className="xgs-spinner xgs-spinner--center" />;
  };

  const center = () => {
    if (routeMapState.mapData?.markers && routeMapState.mapData.markers.length > 0) {
      const activeStop = routeMapState.mapData.markers.find(marker => marker.status === "ACTIVE");
      if (activeStop) {
        return {
          lat: activeStop.lat,
          lng: activeStop.lng
        };
      } else {
        return {
          lat: routeMapState.mapData.markers[0].lat,
          lng: routeMapState.mapData.markers[0].lng
        };
      }
    } else {
      return {
        lat: 0,
        lng: 0
      };
    }
  };

  // useEffect(() => {
  //   dispatch(getRouteMapData((httpStatusCode: number) => {
  //     if (httpStatusCode === 404) navigate(ROUTES.route);
  //   }));
  // }, [dispatch, navigate]);

  useEffect(() => {
    if (routeMapState.requestSucceed &&
      routeMapState.requester === "MAP" &&
      !routeMapState.mapData?.manifest &&
      routeMapState.mapData?.markers.length === 0) navigate(`/${routeMapState.mapData?.manifest}${ROUTES.route}`);
  }, [routeMapState.requestSucceed, routeMapState.requester, routeMapState.mapData?.manifest, routeMapState.mapData?.markers, navigate]);

  return (
    <div className="xgs-route-map">
      <div className="xgs-route-map__container">
        {routeMapState.requestStarted && routeMapState.requester === "MAP" && (
          <Loading isLoading={true} className="xgs-spinner xgs-spinner--center" />
        )}
        {routeMapState.requestSucceed && routeMapState.requester === "MAP" && routeMapState.mapData && (
          <Wrapper
            apiKey={apiKey}
            render={render}
            libraries={["geometry", "marker"]}
          >
            <XGSMap center={center()}>
              {routeMapState.mapData.polyline && <XGSPolyline polylineData={routeMapState.mapData.polyline} />}
              {routeMapState.mapData.markers.map((marker, i) => (
                <XGSMarker
                  key={`m-${i}`}
                  position={{
                    lat: Number(marker.lat),
                    lng: Number(marker.lng)
                  }}
                  label={{
                    text: `${marker.label}`,
                    color: marker.status ? "#FFFFFF" : "#000000",
                    fontSize: marker.status ? "18px" : "16px"
                  }}
                  status={marker.status}
                  onClick={() => {
                    // if (marker.label !== "SC") navigate(ROUTES.stopDetails, {stopNumber: marker.label});
                  }}
                />
              ))}
            </XGSMap>
          </Wrapper>
        )}
        {routeMapState.requestFailed && (
          <XGSErrorMessage className="block-center">
            {routeMapState.requestFailReason || "Error. Please try again later."}
          </XGSErrorMessage>
        )}
      </div>
    </div>
  );
}

export default RouteMap;
